import React, { useState, useEffect, useRef} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Navigation from "../template-parts/Navigation";
import Footer from "../template-parts/Footer";
import Aboutme from "../Components/Aboutme";
import Mywishlist from '../Components/Mywishlist';
import Mesachats from "../Components/Mesachats";
import Panier from "../Components/Panier";
import Espacepro from "../Components/Espacepro";
import Topban from "../Components/Topban";
import Leftlateralcolumn from "../Components/Leftlateralcolumn";
import Rightmaincontent from "../Components/Rightmaincontent";
import Mccol from "../Components/Mccol";


// css : /pages/_moncompte.scss
const Moncompte = () => {
    const [mychoice, setMychoice] = useState('moi');
    const isConnected = useSelector((state) => state.connexionReducer.isConnected);
    const navigate = useNavigate();

    // compte User
    // const User = {
    //     nom: 'Rakoto',
    //     prenom: 'Lita',
    //     adresse: '3, Rue delafortune Antananarivo Madagascar',
    //     telephone: '+261 33 00 123 45',
    //     email: 'rakoto.dupont@mail.com',
    //     points: '0',
    // }
    const User = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : '';
    
    // Définit le contenu (Composant) à afficher dans le backoffice
    let content;
    
    switch (mychoice) {
        case 'moi':
            content = <Aboutme aboutUser={User} />
            break;
        case 'mywishlist':
            content = <Mywishlist />
            break;
        case 'mesachats':
            content = <Mesachats />
            break;
        case 'monpanier':
            content = <Panier />
            break;
        case 'espacepro':
            content = <Espacepro />
            break;
        default:
            content = <Aboutme />
            break;
    }

    // Si n'est pas connecté, alors renvoie à la page d'accueil
    useEffect(() => {
        !isConnected && navigate('/');
    }, [isConnected, navigate]);

    // Ajuster la longueur des colonnes laterales et principales
    const leftColumnRef = useRef(null);
    const rightContentRef = useRef(null);

    useEffect(() => {
        const leftColumnHeight = rightContentRef.current.getBoundingClientRect().height;
        leftColumnRef.current.style.minHeight = `${leftColumnHeight}px`;
    }, [mychoice]);

    return (
        <div className="container">
            <Navigation />
            <Topban user={User} />
            <Leftlateralcolumn leftColumnRef={leftColumnRef} button={ <Mccol setMychoice={setMychoice} mychoice={mychoice} /> } />
            <Rightmaincontent rightContentRef={rightContentRef} content={content} />
            <Footer />
        </div>
    )
}

export default Moncompte;