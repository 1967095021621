import { CONNEXION_STATUS, DISCONNECT_USER } from "../action/connexion.action";

const initialState = {
    isConnected: sessionStorage.getItem('isConnected'),
    user: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : '',
}

export default function connexionReducer(state = initialState, action){
    switch (action.type) {

        case CONNEXION_STATUS:
            sessionStorage.setItem('user', JSON.stringify(action.payload));
            sessionStorage.setItem('isConnected', true);
            return {
                isConnected: sessionStorage.getItem('isConnected'),
                user: JSON.parse(sessionStorage.getItem('user')),
            };
        
        case DISCONNECT_USER:
            sessionStorage.clear();
            return action.payload;
        
        default:
            return state;
        
    }
}