import React from "react";;


const Bomagasin = () => {
    return (
        <div className="monmagasin">
            <h1>A propos du magasin</h1>
            <div className="infoblock">
                <h2>Mes informations</h2>
                <ul>
                    <li><b>Nom du magasin</b> : Mon magasin <button className="myfontawesome"> <i className="fa fa-edit"></i></button></li>
                    <li><b>Adresse</b> : 3, Rue delafortune Antananarivo Madagascar <button className="myfontawesome"> <i className="fa fa-edit"></i></button></li>
                    <li><b>Gérant</b> : Rakoto Dupont <button className="myfontawesome"> <i className="fa fa-edit"></i></button></li>
                    <li><b>Téléphone 01</b> : +261 33 00 123 45 <button className="myfontawesome"> <i className="fa fa-edit"></i></button></li>
                    <li><b>Téléphone 02</b> : +261 32 00 123 45 <button className="myfontawesome"> <i className="fa fa-edit"></i></button></li>
                    <li><b>Page Facebook</b> : <a href="#">Mon beau magasin</a> <button className="myfontawesome"> <i className="fa fa-edit"></i></button></li>
                </ul>
            </div>

        </div>
    )
}

export default Bomagasin;