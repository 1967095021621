import React, { useState } from "react";

//------------------------------WYSIWYG-------------------------------------------
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// css :  './components/_bomagasin.scss'
const Boproducts = () => {
  
//------------------------------Convertir en HTML-----------------------------------
const convertDraftToHtml = (rawContentState) => {
  const contentState = convertFromRaw(rawContentState);
  const editorState = EditorState.createWithContent(contentState);
  return draftToHtml(convertToRaw(editorState.getCurrentContent));
}

//------------------------------Convertir en JS-----------------------------------
const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
const handleEditorChange = (newEditorState) => {
  setEditorState(newEditorState);
} 

  // gestion modals
  const [productmodal, setProductmodal] = useState(false);
  const  showproductmodal = () => {
    setProductmodal(true);
  }
  const hidemodal = (e) => {
    e.target.className == "modal" && setProductmodal(false);
  }
  const closeproductmodal = () => {
    setProductmodal(false);
  }

  return (
    <div className="monmagasin">
      <h1>Mes produits</h1>
      <div className="infoblock">
        <h2>Liste des produits</h2>
        <ul>
          <li>
            <b>Nom </b> : <a href="#">Produit 01</a>
            <button className="myfontawesome"> <i className="fa fa-edit"></i></button>
            <button className="myfontawesome"> <i className="fa fa-trash-alt"></i></button>
          </li>
          <li>
            <b>Nom </b> : <a href="#">Produit 02</a>
            <button className="myfontawesome"> <i className="fa fa-edit"></i></button>
            <button className="myfontawesome"> <i className="fa fa-trash-alt"></i></button>
          </li>
          <li>
            <b>Nom </b> : <a href="#">Produit 03</a>
            <button className="myfontawesome"> <i className="fa fa-edit"></i></button>
            <button className="myfontawesome"> <i className="fa fa-trash-alt"></i></button>
          </li>
          <li>
            <b>Nom </b> : <a href="#">Produit 04</a>
            <button className="myfontawesome"> <i className="fa fa-edit"></i></button>
            <button className="myfontawesome"> <i className="fa fa-trash-alt"></i></button>
          </li>
          <li>
            <b>Nom </b> : <a href="#">Produit 05</a>
            <button className="myfontawesome"> <i className="fa fa-edit"></i></button>
            <button className="myfontawesome"> <i className="fa fa-trash-alt"></i></button>
          </li>
        </ul>
      </div>
      <div className="infoblock">
        <div className="addproductclass">
          <div className="productclass">
            <h3>Ajouter un rayon</h3>
            <input type="text" name="rayon" id="rayon" />
          </div>
          <div className="productclass">
            <h3>Ajouter une catégorie</h3>
            <select name="rayon" id="rayon">
              <option value="rayon">rayon</option>
            </select>
            <input type="text" name="categorie" id="categorie" />
          </div>
          <div className="productclass">
            <h3>Ajouter une sous-catégorie</h3>
            <select name="categorie" id="categorie">
              <option value="categorie">categorie</option>
            </select>
            <input type="text" name="souscategorie" id="souscategorie" />
          </div>
        </div>
      </div>
      <div className="infoblock">
        <h2>Ajouter un produit</h2>
        <button onClick={showproductmodal}>Nouveau produit</button>
      </div>

      <div
        id="productmodal"
        className="modal"
        style={{ display: productmodal && "flex" }}
        onClick={(e) => hidemodal(e)}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2>Ajouter un produit</h2>
          </div>
          <div className="modal-body">
            <div className="formcontainer">
              <form>
                <div className="separatecol">
                  <div className="col01">
                    <label htmlFor="nomproduit">Nom produit</label>
                    <input type="text" name="nomproduit" />
                  </div>
                </div>

                <h3>Classification produit</h3>
                <div className="separatecol">
                  <div className="col02">
                    <label htmlFor="rayon">Rayon</label>
                    <select name="rayon" id="rayon">
                      <option value="maison">maison</option>
                      <option value="deco">deco</option>
                      <option value="informatique">informatique</option>
                    </select>
                  </div>
                  <div className="col01">
                    <label htmlFor="categorie">Catégorie</label>
                    <select name="categorie" id="categorie">
                      <option value="maison">maison</option>
                      <option value="deco">deco</option>
                      <option value="informatique">informatique</option>
                    </select>
                  </div>
                  <div className="col02">
                    <label htmlFor="souscategorie">Sous-catégorie</label>
                    <select name="souscategorie" id="souscategorie">
                      <option value="maison">maison</option>
                      <option value="deco">deco</option>
                      <option value="informatique">informatique</option>
                    </select>
                  </div>
                </div>

                <h3>Références</h3>
                <div className="separatecol">
                  <div className="col01">
                    <label htmlFor="nomproduit">Référence</label>
                    <input type="text" name="nomproduit" />
                  </div>
                  <div className="col02">
                    <label htmlFor="rayon">Marque</label>
                    <select name="rayon" id="rayon">
                      <option value="maison">maison</option>
                      <option value="deco">deco</option>
                      <option value="informatique">informatique</option>
                    </select>
                  </div>
                  <div className="col02">
                    <span></span>
                  </div>
                </div>

                <h3>Prix</h3>
                <div className="separatecol">
                  <div className="col02">
                    <label htmlFor="prix">Prix (en Ar)</label>
                    <input type="number" name="prix" id="prix" />
                  </div>
                  <div className="col02">
                    <label htmlFor="prix">Prix promo (en Ar)</label>
                    <input type="number" name="prixpromo" id="prixpromo" />
                  </div>
                  <div className="col02">
                    <span></span>
                  </div>
                </div>

                <h3>Promo</h3>
                <div className="separatecol">
                  <div className="col02">
                    <label htmlFor="prix">Date début promo</label>
                    <input type="date" name="prix" id="prix" />
                  </div>
                  <div className="col02">
                    <label htmlFor="prix">Date fin promo</label>
                    <input type="date" name="prixpromo" id="prixpromo" />
                  </div>
                  <div className="col02">
                    <span></span>
                  </div>
                </div>

                <h3>Images</h3>
                <div className="separatecol">
                  <div className="col02">
                    <label htmlFor="prix">Image 1</label>
                    <input type="file" name="prix" id="prix" />
                  </div>
                  <div className="col02">
                    <label htmlFor="prix">Image 2</label>
                    <input type="file" name="prixpromo" id="prixpromo" />
                  </div>
                  <div className="col02">
                    <label htmlFor="prix">Image 3</label>
                    <input type="file" name="prixpromo" id="prixpromo" />
                  </div>
                </div>

                <h3>Description courte</h3>
                <div className="separatecol">
                  <div className="col01" style={{backgroundColor: '#fff', color:'#000', minWidth:'300px'}}>
                    <Editor ></Editor>
                  </div>
                </div>
                <h3>Description longue</h3>
                <div className="separatecol">
                  <div className="col01"  style={{backgroundColor: '#fff', color:'#000', minWidth:'300px'}}>
                    <Editor ></Editor>
                  </div>
                </div>

                <input type="submit" value="Enregistrer" />
             
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button onClick={closeproductmodal}>Fermer</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boproducts;
