import axios from "axios";

export const CONNEXION_STATUS = 'CONNEXION_STATUS';
export const DISCONNECT_USER = 'DISCONNECT_USER';

export const connectUser = (data) => {
    return (dispatch) => {
        return axios.post('https://exo.axel.mg/connectme', data).then((res) => {
            dispatch({ type: CONNEXION_STATUS, payload: res.data });
        });
    }
}

export const disconnectUser = () => {
    return (dispatch) => {
        return axios.post('https://exo.axel.mg/disconnect').then((res) => {
            dispatch({type: DISCONNECT_USER, payload: res.data})
        })
    }
}