import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style/index.scss';

// -------------- Redux ------------------
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';

// ------------- Session Storage ------------
// sessionStorage.setItem('user', '');
// sessionStorage.setItem('isConnected', false);

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store} >
        <App />
    </Provider>
);

