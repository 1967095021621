import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg';

// css : './components/_espacepro.scss'
const Espacepro = () => {
    // Dispose déjà d'un magasin ou non
    const navigate = useNavigate();
    const [propriomagasin, setPropriomagasin] = useState(false);

    // Toggle de la form
    const [displayformpart, setDisplayformpart] = useState(Number(1));

    const addnumber = () => {
        displayformpart < 3 && setDisplayformpart(displayformpart + 1);
    }
    const menonumber = () => {
        displayformpart > 1 && setDisplayformpart(displayformpart -1);
    }

    const submitshop = (e) => {
        e.preventDefault();
        setPropriomagasin(true)
    }
    

    useEffect(() => {
        propriomagasin && navigate('/backoffice')
    }, [propriomagasin]);

    return(
        <div className="monmagasin">
            <h1>Mon magasin</h1>
            <div className="infoblock">
                <h2>Créer un magasin</h2>
                <form className="createshop">
                    <span className="partone" style={{display : (displayformpart == 1) ? 'block' : 'none'}}>
                        <h3>Etape {displayformpart}/3 : Coordonnées</h3>
                        <label htmlFor="nommagasin">Nom de la boutique</label>
                        <input className="maginput" type="text" name="nommagasin" id="nommagasin" />
                        <label htmlFor="adresse">Adresse</label>
                        <input className="maginput" type="text" name="adresse" id="adresse" />
                        <label htmlFor="email">Email</label>
                        <input className="maginput" type="email" name="email" id="email" />
                        <label htmlFor="phone">Téléphone</label>
                        <input className="maginput" type="text" name="phone" id="phone" />
                    </span>

                    <span className="parttwo" style={{display : (displayformpart == 2) ? 'block' : 'none'}}>
                        <h3>Etape {displayformpart}/3 : A propos de la boutique</h3>
                        <label htmlFor="categorie">Catégorie</label>
                        <select name='categorie' id='categorie'>
                            <option defaultValue="1">Electronique</option>
                            <option defaultValue="2">Informatique</option>
                            <option defaultValue="3">Maison</option>
                            <option defaultValue="4">Jardin</option>
                        </select>
                        <label htmlFor="description">Description</label>
                        <textarea name='description' rows={4} > </textarea>
                    </span>

                    <span className="partthree" style={{display : (displayformpart == 3) ? 'block' : 'none'}}>
                        <h3>Etape {displayformpart}/3 : Informations additionnelles</h3>
                        <label htmlFor="cgv">Conditions Générales de ventes</label>
                        <p>Importez votre CGV ici</p>
                        <input type="file" name="cgv" id="cgv" />
                        <p>ou bien, remplissez-les ici</p>
                        <div style={{background : '#fff'}}>
                            <Editor></Editor>
                        </div>
                        <input type="submit" onClick={(e) => submitshop(e)} defaultValue="Enregistrer" />

                    </span>


                </form>
                
                {displayformpart != 1 && <button onClick={menonumber}>Précédent</button>}
                {displayformpart != 3 && <button onClick={addnumber}>Suivant</button>}
            </div>

        </div>
    )

}

export default Espacepro;