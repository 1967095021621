import React, { useEffect, useRef, useState } from "react";
import Bomagasin from "../Components/Bomagasin";
import Boproducts from "../Components/Boproducts";
import Bocommands from "../Components/Bocommands";
import Navigation from "../template-parts/Navigation";
import Footer from "../template-parts/Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Boban from "../Components/Boban";
import Rightmaincontent from "../Components/Rightmaincontent";
import Bocol from "../Components/Bocol";
import Leftlateralcolumn from "../Components/Leftlateralcolumn";


// CSS : pages/_backoffice.scss
const Backoffice = () => {
    const [mychoice, setMychoice] = useState('magasin');
    const isConnected = useSelector((state) => state.connexionReducer.isConnected);
    const navigate = useNavigate();
    
    // Définit le contenu (Composant) à afficher dans le backoffice
    let content;
    switch (mychoice) {
        case 'magasin':
            content = <Bomagasin />
            break;
        case 'products':
            content = <Boproducts />
            break;
        case 'commands':
            content = <Bocommands />
            break;
        default:
            content = <Bomagasin />
            break;
    }

    // Si n'est pas connecté, alors renvoie à la page d'accueil
    useEffect(() => {
        !isConnected && navigate('/');
    }, [isConnected, navigate]);

    const leftColumnRef = useRef(null);
    const rightContentRef = useRef(null);

    useEffect(() => {
        const leftColumnHeight = rightContentRef.current.getBoundingClientRect().height;
        leftColumnRef.current.style.minHeight = `${leftColumnHeight}px`;
    }, [mychoice])

    return (
        <div className="container">
            <Navigation />
            <Boban />
            <Leftlateralcolumn leftColumnRef={leftColumnRef} button={ <Bocol setMychoice={setMychoice} mychoice={mychoice} /> } />
            <Rightmaincontent rightContentRef={rightContentRef}  content={content}/>
            <Footer />
        </div>
    ) 
}

export default Backoffice;