import React from "react";

// css : ./components/_aboutme.scss
const Aboutme = ({aboutUser}) => {

    return (
        <div className="monmagasin">
            <h1>A propos de moi</h1>
            <div className="infoblock">
                <h2>Mes informations</h2>
                <ul>
                    <li><b>Nom </b> : { aboutUser.nom }<button className="myfontawesome"> <i className="fa fa-edit"></i></button></li>
                    <li><b>Prénom </b> : { aboutUser.prenom }<button className="myfontawesome"> <i className="fa fa-edit"></i></button></li>
                    <li><b>Adresse</b> : { aboutUser.adresse } <button className="myfontawesome"> <i className="fa fa-edit"></i></button></li>
                    <li><b>Téléphone 01</b> : { aboutUser.telephone } <button className="myfontawesome"> <i className="fa fa-edit"></i></button></li>
                    <li><b>Email</b> : { aboutUser.email } <button className="myfontawesome"> <i className="fa fa-edit"></i></button></li>
                    {/* <li><b>Nom Facebook</b> : <a href="#">Mon beau magasin</a> <button className="myfontawesome"> <i className="fa fa-edit"></i></button></li> */}
                </ul>
            </div>

        </div>
    )
}

export default Aboutme;