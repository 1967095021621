import axios from 'axios';

export const CREATE_USER = 'CREATE_USER';

export const createUser = (data) => {
    return (dispatch) => {
        return axios.post('https://exo.axel.mg/createaccount', data).then(() => {
            dispatch({ type: CREATE_USER, payload: data });
        });
        
    }
}

