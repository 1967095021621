import React from 'react';

const Bocommands = () => {
    return (
        <div className="monmagasin">
            <h1>Liste des commandes</h1>
            <div className="infoblock">
                <p>Pas de commande en cours</p>
            </div>
        </div>
    )
}

export default Bocommands;