import React, { useState } from 'react';
import Navigation from '../template-parts/Navigation';
import Footer from '../template-parts/Footer';

const Home = () => {


    return (
        <div className="container">
            <Navigation />
            <div className="hpban">
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores aperiam repellendus repellat reprehenderit omnis sed animi voluptatibus, dolores ut a enim laboriosam iure dolor ea? Fuga ex minus odit quisquam.</p>
            </div>
            <Footer />
        </div>
    )

}

export default Home;